import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	header: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		fontSize: '2.0243rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.8219rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5625rem',
		},
	},
}));

export const Graphic = ({ header, paymentsGraphic }) => {
	const classes = useStyles();

	return (
		<div style={{ padding: '2rem 0' }}>
			<PortableText
				content={header}
				serializers={{
					h2: ({ children }) => (
						<Typography
							component='h2'
							color='primary'
							className={classes.header}>
							{children}
						</Typography>
					),
					strong: ({ children }) => (
						<span style={{ color: '#2a7abc' }}>{children}</span>
					),
					normal: ({ children }) => (
						<Typography
							variant='body1'
							style={{ lineHeight: '32px', marginTop: '2rem' }}>
							{children}
						</Typography>
					),
				}}
			/>
			<Grid container justifyContent='center' style={{ padding: '2rem 0' }}>
				<GatsbyImage image={paymentsGraphic.asset?.gatsbyImageData} />
			</Grid>
		</div>
	);
};
