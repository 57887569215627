import React, { useRef, useState } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import {
	Container,
	Typography,
	useTheme,
	Grid,
	useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../../components/FinancialServices/Payments/Hero';
import { WaveUpSVG } from '../../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../../components/SEO';
import { Overview } from '../../components/FinancialServices/Payments/Overview';
import { Graphic } from '../../components/FinancialServices/Payments/Graphic';
import { Feature } from '../../components/FinancialServices/Payments/Feature';
import { CenterWave } from '../../components/FinancialServices/Payments/CenterWave';
import { Benefits } from '../../components/FinancialServices/Payments/Benefits';
import { Testimonials } from '../../components/Testimonials';
// import { TestimonialCarousel } from '../../components/Testimonials/TestimonialCarousel';
import { CustomerLogos } from '../../components/CustomerLogos';
import { HeroVideo } from '../../components/FinancialServices/Payments/Hero/HeroVideo';
import { HeroModal } from '../../components/Partners/Hero/HeroModal';
// import { Form } from '../../components/ContactForm/Form';

const TestimonialCarousel = loadable(() =>
	import('../../components/Testimonials/TestimonialCarousel')
);
const Form = loadable(() => import('../../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	testimonialHeader: {
		color: theme.white,
		fontWeight: 700,
		padding: '2rem 0',
	},
	testimonialBackground: {
		height: '100%',
		padding: '2rem 0 26rem 0',
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

const Payments = ({ data, location }) => {
	const payments = data.fs.edges[0].node.payments;
	const classes = useStyles();
	const formRef = useRef();

	const [modalOpen, setModalOpen] = useState(false);

	const theme = useTheme();

	const {
		paymentsPageHero,
		testimonialHeader,
		testimonials,
		_rawGraphicSectionHeader,
		metaDescription,
		metaTitle,
		benefitsCards,
		benefitsHeader,
		benefitsSubheader,
		contactForm,
		customerLogos,
		formBgImage,
		formImages,
		marketoId,
		pardotUrl,
		paymentPageFeatures,
		_rawPaymentsOverview,
		centerWaveBackground,
		paymentsGraphic,
	} = payments;
	const med = useMediaQuery('(max-width: 960px)');

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={paymentsPageHero} />
			<Container>
				<HeroVideo
					thumbnailImage={paymentsPageHero.thumbnailImage}
					wistiaLink={paymentsPageHero.wistiaLink}
				/>
				<Overview overview={_rawPaymentsOverview} />
				<Graphic
					header={_rawGraphicSectionHeader}
					paymentsGraphic={paymentsGraphic}
				/>
				{paymentPageFeatures.map((feature, index) => (
					<Feature
						feature={feature}
						index={index}
						accentColor={theme.workwaveBlue}
						setModalOpen={setModalOpen}
					/>
				))}
			</Container>
			<CenterWave background={centerWaveBackground} />
			<Container>
				<Benefits
					header={benefitsHeader}
					subheader={benefitsSubheader}
					cards={benefitsCards}
				/>
			</Container>
			<div style={{ marginTop: '-19rem' }}>
				<WaveDownSVG fill='#FFF' />
			</div>
			<div
				className={classes.testimonialBackground}
				style={{
					background: `linear-gradient(180deg, ${theme.workwaveBlue} 50%, #FFFFFF 50%)`,
					paddingTop: '19rem',
					marginBottom: '-19rem',
				}}>
				<Container>
					<Grid container direction='row' justify='center' alignItems='center'>
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							<Typography variant='h2' className={classes.testimonialHeader}>
								{testimonialHeader}
							</Typography>
						</Grid>
					</Grid>
					{med ? (
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'>
							<TestimonialCarousel
								carouselTestimonialArray={testimonials}
								ref={formRef}
							/>
						</Grid>
					) : (
						<Testimonials
							header={testimonialHeader}
							testimonials={testimonials}
							formRef={formRef}
						/>
					)}
				</Container>
			</div>
			<Container>
				<CustomerLogos customerLogosArray={customerLogos} />
			</Container>
			<div>
				<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
			</div>

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<Form
					formId={marketoId}
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					formImages={formImages}
					location={location}
				/>
			</div>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			<HeroModal
				open={modalOpen}
				setHeroModalOpen={setModalOpen}
				wistiaLink='https://fast.wistia.net/embed/iframe/ut69z3p2cs'
				playing
			/>
		</>
	);
};

export const query = graphql`
	query FSPaymentsPageQuery {
		fs: allSanityFinancialServices {
			edges {
				node {
					payments: fsPaymentsPage {
						metaTitle
						metaDescription
						paymentsPageHero {
							backgroundImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							thumbnailImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							wistiaLink
							_rawContent
						}
						_rawGraphicSectionHeader
						paymentsGraphic {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						paymentPageFeatures {
							_key
							title
							bodyHeader
							image {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							ctaText
							ctaLink
							_rawBodyCopy
						}
						centerWaveBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						benefitsHeader
						benefitsSubheader
						benefitsCards {
							faIcon
							header
							Body
							accentColor {
								hexValue
							}
						}
						testimonialHeader
						testimonials {
							title
							header
							testimonialLogo {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							testimonialText
							nameAndTitle
							company
							image {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						customerLogos {
							title
							logo {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
						contactForm {
							header
							privacyPolicy
						}
						formImages {
							image {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							link
						}
						formBgImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						marketoId
						pardotUrl
						paymentsOverview {
							_rawChildren
						}
						_rawPaymentsOverview
					}
				}
			}
		}
	}
`;

export default Payments;
