import React from 'react';
import PortableText from 'react-portable-text';

import { Typography } from '@material-ui/core';

export const Overview = ({ overview }) => {
	return (
		<div style={{ padding: '2rem 0', marginTop: '2rem' }}>
			<PortableText
				content={overview}
				serializers={{
					h2: ({ children }) => (
						<Typography
							variant='h2'
							color='primary'
							style={{ fontWeight: '700' }}>
							{children}
						</Typography>
					),
					strong: ({ children }) => (
						<span style={{ color: '#2a7abc' }}>{children}</span>
					),
					normal: ({ children }) => (
						<Typography
							variant='body1'
							style={{ lineHeight: '32px', marginTop: '2rem' }}>
							{children}
						</Typography>
					),
				}}
			/>
		</div>
	);
};
