import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { HighlightCard } from '../HighlightCard';

export const Benefits = ({ header, subheader, cards }) => {
	return (
		<div style={{ padding: '2rem 0' }}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				spacing={2}
				style={{ textAlign: 'center', padding: '2rem 0 4rem 0' }}>
				<Typography variant='h2' color='primary'>
					{header}
				</Typography>
				<Typography variant='body1'>{subheader}</Typography>
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
				spacing={3}>
				{cards.map((card, index) => (
					<HighlightCard key={index} card={card} />
				))}
			</Grid>
		</div>
	);
};
