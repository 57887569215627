import React, { useEffect, useState, useRef } from 'react';
// import { useElementSize } from 'use-element-size';

export const ReverseWaveDownSVG = ({ fill, oneSide, heroRef, opacity = 1 }) => {
	const [height, setHeight] = useState(0);
	const ref = useRef(null);
	const browser = typeof window !== 'undefined';

	const onResize = () => {
		setHeight(ref.current?.clientHeight);
	};

	useEffect(() => {
		browser && window.addEventListener('resize', onResize);

		setHeight(ref.current?.clientHeight);
	});

	return (
		<svg
			class='wave-top-smile'
			role='presentation'
			ref={ref}
			viewBox='0 0 2160 265'
			fill={fill}
			opacity={opacity}
			xmlns='http://www.w3.org/2000/svg'
			// xmlns:xlink='http://www.w3.org/1999/xlink'
			style={{ width: '100%', marginTop: '-4px' }}
			preserveAspectRatio='none'
			shape-rendering='auto'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M-2.32107e-05 0.000188833L1080 1.00009L2160 0L2160 145.5C1800 225.5 1440 265.5 1080 265.5C720 265.5 360 225.5 0.000477791 145.5L-2.32107e-05 0.000188833Z'
				fill={fill}
			/>
		</svg>
	);
};
