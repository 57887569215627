import React from 'react';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container } from '@material-ui/core';

import { WaveUpHero } from '../../../WaveSVGs/WaveUpHero';
import { HeroBody } from '../../../CustomStyles/Typography';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.white,
		marginBottom: '1rem',
		textAlign: 'center',
	},
	header: {
		lineHeight: 1.1,
		marginTop: '50px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
			marginTop: '20px',
		},
	},
	imgCont: {
		padding: '6rem 0',
		maxWidth: '100vw',
		height: '100%',
		overflowX: 'hidden',
		backgroundSize: 'cover',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
		[theme.breakpoints.down('md')]: {},

		[theme.breakpoints.down('xs')]: {
			padding: '3rem 0',
			backgroundPosition: 'right',
		},
	},
	imgRight: {
		marginLeft: '-10rem',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '-9rem',
			height: '560px',
		},
		// [theme.breakpoints.down('md')]: {
		// 	marginLeft: '-4rem',
		// 	height: '380px',
		// },
		[theme.breakpoints.down('sm')]: {
			marginLeft: '-8rem',
			height: '300px',
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0',
			height: '260px',
		},
	},
	star: {
		marginRight: '.5rem',
		color: '#FFCA43',
	},
	wave: {
		[theme.breakpoints.down('lg')]: {},
		// [theme.breakpoints.down('md')]: {
		// 	marginTop: '-7rem',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	marginTop: '-7rem',
		// },
		[theme.breakpoints.down('xs')]: {},
	},
}));

export const Hero = ({ hero, accentColor, leadership }) => {
	const classes = useStyles();
	return (
		<>
			<BgImage
				className={classes.imgCont}
				image={hero?.backgroundImage?.asset?.gatsbyImageData}
				style={{
					backgroundImage: `url(${hero?.backgroundImage?.asset?.gatsbyImageData.images.fallback.src})`,
					lineHeight: '0',
					overflow: 'hidden',
				}}>
				<Container fixed>
					<Grid container direction='row' justifyContent='center'>
						<Grid
							container
							item
							direction='column'
							justify='center'
							alignItems='center'
							xs={12}
							md={10}>
							{hero?._rawContent.map((content, index) => (
								<PortableText
									key={index}
									content={content}
									className={classes.text}
									serializers={{
										h1: ({ children }) => (
											<Typography variant='h1' className={classes.header}>
												{children}
											</Typography>
										),
										h2: ({ children }) => (
											<Typography variant='h2' className={classes.header}>
												{children}
											</Typography>
										),
										h4: ({ children }) => (
											<Typography variant='h4' className={classes.header}>
												{children}
											</Typography>
										),
										normal: ({ children }) => (
											<HeroBody leadership={leadership}>{children}</HeroBody>
										),
									}}
								/>
							))}{' '}
						</Grid>
					</Grid>
				</Container>
				<WaveUpHero height='213' width='100%' fill='white' />
			</BgImage>
		</>
	);
};
